import React from 'react'
import styled from 'styled-components'
import arrow from '../../../images/down-arrow.svg'
import q from '../../../images/question.svg'
import theme from '../../styling/theme'

interface QuestionProps {
  children: React.ReactNode
  question: string
  last?: boolean
  first?: boolean
  isOpen?: boolean
  toggleAnswer(): void
}

interface ContainerProps {
  last?: boolean
  first?: boolean
}

const FaqQuestion = ({
  question,
  isOpen,
  children,
  last,
  first,
  toggleAnswer,
}: QuestionProps) => {
  return (
    <Container last={last} first={first}>
      <Question isOpen={isOpen} onClick={toggleAnswer}>
        <p>
          <img src={q} alt="Question Icon" />
          {question}
        </p>
        <FaqButton isOpen={isOpen} src={arrow} alt="Toggle Answer" />
      </Question>
      <AnswerContainer isOpen={isOpen}>
        <Answer isOpen={isOpen}>{children}</Answer>
      </AnswerContainer>
    </Container>
  )
}

export default FaqQuestion

// Styled Components

const Container = styled.div<ContainerProps>`
  width: 100%;
  border-bottom: 1px solid #eee;
  padding: 30px 0;
  border-top: ${props => props.first && '1px solid #eee'};
`

const Question = styled.div<{ isOpen?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  user-select: none;

  & p {
    display: flex;
    margin: 0;
    font-size: 14px;
    line-height: 20px;

    @media (min-width: 1024px) {
      font-size: 18px;
      line-height: 26px;
    }

    & img {
      width: 23px;
      margin-right: 10px;
    }
  }

  @media (max-width: 1023px) {
    padding: 0 20px;
    
    & p {
      width: 80%;
    }
  }
`

interface FaqButtonProps {
  isOpen?: boolean
}

const FaqButton = styled.img<FaqButtonProps>`
  width: 18px;
  transition: all 0.2s ease;
  ${props => props.isOpen && `transform: rotate(180deg);`}
`

interface IAnswerProps {
  isOpen: boolean
}

const AnswerContainer = styled.div<IAnswerProps>`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
  ${props => props.isOpen && `max-height: 500px;`}
`

const Answer = styled.div<IAnswerProps>`
  padding: 42px 0;

  @media only screen and (max-width: 1023px) {
    padding: 20px 0 0;

    ${props => props.isOpen && `padding: 24px 0;`}
  }

  & p {
    margin-bottom: 10px;
    color: ${theme.text.second}; 
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    padding: 0 53px;

    @media (min-width: 1023px) {
      font-size: 18px;
      line-height: 28px;
      padding: 0 33px;
    }
  }

  /* Style for lists inside the answer */
  & ul {
    margin: 10px 53px;
    list-style-type: disc;
    color: ${theme.text.second};
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;

    @media (min-width: 1023px) {
      font-size: 18px;
      line-height: 28px;
      margin: 10px 33px;
    }
  }

  & li {
    margin-bottom: 6px;
    font-size: 18px;
  }
`
