import React, { useState } from 'react'
import styled from 'styled-components'
import FaqQuestionWhite from './FaqQuestionWhite'

interface IQuestions {
  question: string
  answer: React.ReactNode
}

interface FaqProps {
  questions: Array<IQuestions>
}

const Faq = ({ questions }: FaqProps) => {
  const [activeQuestion, setActiveQuestion] = useState<number | null>(null)

  const toggleQuestion = (index: number) => {
    if (activeQuestion === index) {
      setActiveQuestion(null)
    } else {
      setActiveQuestion(index)
    }
  }

  return (
    <FaqContainer>
      {questions.map((item, index) => (
        <FaqQuestionWhite
          key={index}
          question={item.question}
          isOpen={index === activeQuestion}
          first={index === 0}
          last={index === questions.length - 1}
          toggleAnswer={() => toggleQuestion(index)}
        >
          {item.answer}
        </FaqQuestionWhite>
      ))}
    </FaqContainer>
  )
}

export default Faq

// Styled Components
const FaqContainer = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 20px; /* small padding */
`
