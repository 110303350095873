import React from 'react'
import styled from 'styled-components'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'
import Button from '../shared/Button/Button'

interface ICardProps {
  heading: string
  content?: string[]
  list?: string[]
  ctaUrl: string
  ctaText: string
}

interface ITwoCardSectionProps {
  leftCard: ICardProps
  rightCard: ICardProps
}

/**
 * Displays two cards side by side (stacked on mobile).
 * Each card:
 *   - heading
 *   - optional paragraphs
 *   - optional list
 *   - single CTA button
 */
const TwoCardSection = ({ leftCard, rightCard }: ITwoCardSectionProps) => {
  return (
    <SectionContainer>
      <MainGrid>
        <CardsContainer>
          <Card>
            <h3>{leftCard.heading}</h3>
            {leftCard.content?.map((paragraph, i) => (
              <p key={i} className="p-large">{paragraph}</p>
            ))}

            {leftCard.list && (
              <ul>
                {leftCard.list.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            )}

            <ButtonWrapper>
              <a href={leftCard.ctaUrl}>
                <Button>{leftCard.ctaText}</Button>
              </a>
            </ButtonWrapper>
          </Card>

          <Card>
            <h3>{rightCard.heading}</h3>
            {rightCard.content?.map((paragraph, i) => (
              <p key={i} className="p-large">{paragraph}</p>
            ))}

            {rightCard.list && (
              <ul>
                {rightCard.list.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            )}

            <ButtonWrapper>
              <a href={rightCard.ctaUrl}>
                <Button>{rightCard.ctaText}</Button>
              </a>
            </ButtonWrapper>
          </Card>
        </CardsContainer>
      </MainGrid>
    </SectionContainer>
  )
}

export default TwoCardSection

// ----- STYLES -----

const SectionContainer = styled.section`
  margin-bottom: 15px;
`

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const Card = styled.div`
  box-shadow: 0 -29px 54px 1px rgba(100, 100, 100, 0.1);
  background: white;
  padding: 52px 30px 43px;
  border-radius: 4px;
  margin-top: 25px;

  @media (min-width: 768px) {
    width: 48%;
    margin-top: 0;
  }

  @media (min-width: 1024px) {
    padding: 80px 55px 75px;
  }

  h3 {
    margin-bottom: 17px;

    @media (min-width: 768px) {
      margin-bottom: 24px;
    }
  }

  .p-large {
    margin-bottom: 10px;
    color: ${theme.text.greyLight};
  }

  ul {
    margin-bottom: 30px;
    list-style-type: none; /* or keep default bullets if you prefer */
    padding: 0;

    @media (min-width: 1024px) {
      margin-bottom: 25px;
    }
  }

  li {
    color: ${theme.text.greyLight};
    font-size: 14px;
    font-weight: 300;
    line-height: 27px;
    margin-bottom: 6px;
    
    @media (min-width: 1024px) {
      font-size: 18px;
      line-height: 28px;
    }

    /* Example bullet or prefix styling */
    &::before {
      content: '• ';
      margin-right: 4px;
    }
  }
`

const ButtonWrapper = styled.div`
  margin-top: 20px;

  a {
    text-decoration: none;
  }
`
